import React from 'react';
import { Thumbnail2d, ThumbnailTypes } from 'roblox-thumbnails';
import { Thumbnail3d } from 'roblox-thumbnail-3d';
import ItemDetailsThumbnailService, {
  TAvatarRenderAsset,
  TAvatarResult,
  TColor
} from '../services/itemDetailsThumbnailService';
import { thumbnailConstants } from '../constants/itemDetailsThumbnailConstants';

export function ItemDetailsThumbnailView({
  targetId,
  altTargetId,
  isBundle,
  isAnimation,
  isUserOutfit,
  mode3DEnabled,
  tryOnEnabled,
  avatar,
  avatarTryOnAssets,
  bodyColors
}: {
  targetId: number;
  altTargetId: number;
  isBundle: boolean;
  isAnimation: boolean;
  isUserOutfit: boolean;
  mode3DEnabled: boolean;
  tryOnEnabled: boolean;
  avatar: TAvatarResult | undefined;
  avatarTryOnAssets: Array<TAvatarRenderAsset> | undefined;
  bodyColors: Array<TColor> | undefined;
}): JSX.Element {
  const get3DEndpointCall = () => {
    if (isBundle && !isAnimation && isUserOutfit) {
      // This will be moved to bundle 3D thumbnail when ready
      return ItemDetailsThumbnailService.get3DOutfitThumbnail(altTargetId);
    }
    if (isBundle && !isAnimation && !isUserOutfit) {
      // This will be moved to bundle 3D thumbnail when ready
      return ItemDetailsThumbnailService.get3DAssetThumbnail(altTargetId);
    }
    if (isBundle && isAnimation) {
      return ItemDetailsThumbnailService.get3DAnimatedAssetThumbnail(altTargetId);
    }
    if (isAnimation) {
      return ItemDetailsThumbnailService.get3DAnimatedAssetThumbnail(targetId);
    }
    return ItemDetailsThumbnailService.get3DAssetThumbnail(targetId);
  };

  if (mode3DEnabled) {
    if (
      tryOnEnabled &&
      avatarTryOnAssets !== undefined &&
      avatar !== undefined &&
      bodyColors !== undefined
    ) {
      const get3DTryonEndpointCall = () => {
        return ItemDetailsThumbnailService.postAvatarRender(
          avatarTryOnAssets,
          avatar,
          3,
          bodyColors
        );
      };
      return (
        <Thumbnail3d
          type='TryOn'
          targetId={3}
          getThumbnailJson={get3DTryonEndpointCall}
          loadingClass='thumbnail-loading thumbnail-placeholder shimmer'
        />
      );
    }

    return (
      <Thumbnail3d
        type={isBundle ? ThumbnailTypes.bundleThumbnail : ThumbnailTypes.assetThumbnail}
        targetId={targetId}
        getThumbnailJson={get3DEndpointCall}
        loadingClass='thumbnail-loading thumbnail-placeholder shimmer'
      />
    );
  }
  if (
    tryOnEnabled === true &&
    avatarTryOnAssets !== undefined &&
    avatar !== undefined &&
    bodyColors !== undefined
  ) {
    const get2DTryonEndpointCall = () => {
      return ItemDetailsThumbnailService.postAvatarRender2D(
        avatarTryOnAssets,
        avatar,
        4,
        bodyColors
      );
    };
    return (
      <Thumbnail2d
        type='TryOn'
        targetId={2}
        size={thumbnailConstants.size}
        getThumbnail={get2DTryonEndpointCall}
      />
    );
  }
  if (isBundle && isAnimation) {
    return (
      <Thumbnail2d
        type={ThumbnailTypes.assetThumbnail}
        targetId={altTargetId}
        size={thumbnailConstants.size}
      />
    );
  }
  if (isBundle && !isAnimation && !isUserOutfit) {
    return (
      <Thumbnail2d
        type={ThumbnailTypes.assetThumbnail}
        targetId={altTargetId}
        size={thumbnailConstants.size}
      />
    );
  }
  return (
    <Thumbnail2d
      type={isBundle ? ThumbnailTypes.bundleThumbnail : ThumbnailTypes.assetThumbnail}
      targetId={targetId}
      size={thumbnailConstants.size}
    />
  );

  return (
    <React.Fragment>
      <div className='thumbnail-loading thumbnail-placeholder shimmer' />
    </React.Fragment>
  );
}
export default ItemDetailsThumbnailView;
