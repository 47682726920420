/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { AxiosPromise, httpService } from 'core-utilities';
import { Thumbnail } from 'roblox-thumbnails';
import { AvatarAccoutrementService, AccoutrementAsset } from 'Roblox';
import urlConfigs from '../constants/urlConfigs';
import { thumbnailConstants } from '../constants/itemDetailsThumbnailConstants';

export type TAvatarResult = {
  scales: {
    height: number;
    width: number;
    head: number;
    depth: number;
    proportion: number;
    bodyType: number;
  };
  playerAvatarType: string;
  bodyColors: {
    headColorId: number;
    torsoColorId: number;
    rightArmColorId: number;
    leftArmColorId: number;
    rightLegColorId: number;
    leftLegColorId: number;
  };
  assets: Array<AccoutrementAsset>;
};
export type TAvatarRules = {
  bodyColorsPalette: Array<TColor>;
};
export type TColor = {
  brickColorId: number;
  hexColor: string;
};
export type TAvatarRenderAsset = {
  id: number;
  meta: TMeta;
};
export type TMeta = {
  order: number;
  version: number;
};
export type TAssetDetailsResult = {
  data: Array<TAssetDetailsEntry>;
};

export type TAssetDetailsEntry = {
  id: number;
  name: string;
  itemRestrictions: Array<string>;
  itemType: string;
  itemStatus: Array<string>;
  assetType: number;
};

export type TBundleDetailsResult = {
  data: Array<TBundleDetailsEntry>;
};

export type TBundleDetailsEntry = {
  bundleType: string;
  itemRestrictions: Array<string>;
  items: Array<TBundleItem>;
};

export type TBundleItem = {
  id: number;
  type: string;
};

export type T3DAssetThumbnailResult = {
  data: T3DAssetThumbnailEntry;
};

export type T3DAssetThumbnailEntry = {
  targetId: number;
  status: string;
  imageUrl: string;
};

export const ItemDetailsThumbnailService = {
  getAvatar(): AxiosPromise<TAvatarResult> {
    return httpService.get(urlConfigs.getAvatar);
  },
  getAvatarRules(): AxiosPromise<TAvatarRules> {
    return httpService.get(urlConfigs.avatarRules);
  },
  getColorHex(colorId: number, bodyColors: Array<TColor>): string {
    let hexColor = thumbnailConstants.defaultBodyColor;
    bodyColors.forEach((color: TColor) => {
      if (color.brickColorId === colorId) {
        hexColor = color.hexColor;
      }
    });
    return hexColor;
  },
  postAvatarRender(
    assets: Array<TAvatarRenderAsset>,
    avatarData: TAvatarResult,
    renderType: number,
    bodyColor: Array<TColor>
  ): AxiosPromise<unknown> {
    const requestBody = {
      thumbnailConfig: {
        thumbnailId: renderType,
        thumbnailType: `${renderType}d`,
        size: thumbnailConstants.size
      },
      avatarDefinition: {
        assets,
        bodyColors: {
          headColor: this.getColorHex(avatarData.bodyColors.headColorId, bodyColor),
          leftArmColor: this.getColorHex(avatarData.bodyColors.leftArmColorId, bodyColor),
          leftLegColor: this.getColorHex(avatarData.bodyColors.leftLegColorId, bodyColor),
          rightArmColor: this.getColorHex(avatarData.bodyColors.rightArmColorId, bodyColor),
          rightLegColor: this.getColorHex(avatarData.bodyColors.rightLegColorId, bodyColor),
          torsoColor: this.getColorHex(avatarData.bodyColors.torsoColorId, bodyColor)
        },
        scales: avatarData.scales,
        playerAvatarType: {
          playerAvatarType: avatarData.playerAvatarType
        }
      }
    };
    return httpService.post(urlConfigs.avatarRender, requestBody);
  },
  // This should be changed in the future, but the custom thumbnail endpoint UI wants <Thumbnail> for 2d and <unknown> for 3d
  postAvatarRender2D(
    assets: Array<TAvatarRenderAsset>,
    avatarData: TAvatarResult,
    renderType: number,
    bodyColor: Array<TColor>
  ): AxiosPromise<Thumbnail> {
    const requestBody = {
      thumbnailConfig: {
        thumbnailId: renderType,
        thumbnailType: renderType === 4 ? '2dWebp' : `${renderType}d`,
        size: thumbnailConstants.size
      },
      avatarDefinition: {
        assets,
        bodyColors: {
          headColor: this.getColorHex(avatarData.bodyColors.headColorId, bodyColor),
          leftArmColor: this.getColorHex(avatarData.bodyColors.leftArmColorId, bodyColor),
          leftLegColor: this.getColorHex(avatarData.bodyColors.leftLegColorId, bodyColor),
          rightArmColor: this.getColorHex(avatarData.bodyColors.rightArmColorId, bodyColor),
          rightLegColor: this.getColorHex(avatarData.bodyColors.rightLegColorId, bodyColor),
          torsoColor: this.getColorHex(avatarData.bodyColors.torsoColorId, bodyColor)
        },
        scales: avatarData.scales,
        playerAvatarType: {
          playerAvatarType: avatarData.playerAvatarType
        }
      }
    };
    return httpService.post(urlConfigs.avatarRender, requestBody);
  },
  convertAssetToAvatarRenderAsset(asset: AccoutrementAsset): TAvatarRenderAsset {
    let meta;
    if (AvatarAccoutrementService.isLayeredClothing(asset.assetType.id)) {
      meta = {
        order:
          asset.meta !== undefined && asset.meta.order !== undefined
            ? asset.meta.order
            : AvatarAccoutrementService.getLayeredClothingAssetOrder(asset.assetType.id),
        version: 1
      } as TMeta;
      return {
        id: asset.id,
        meta
      } as TAvatarRenderAsset;
    }

    return {
      id: asset.id
    } as TAvatarRenderAsset;
  },
  postAssetDetails(targetId: number): AxiosPromise<TAssetDetailsResult> {
    const requestBody = {
      items: [
        {
          itemType: 'Asset',
          id: targetId
        }
      ]
    };
    return httpService.post(urlConfigs.assetItemDetails, requestBody);
  },
  postAssetDetailsArray(targetIds: Array<number>): AxiosPromise<TAssetDetailsResult> {
    const items = new Array<unknown>();
    targetIds.map(item =>
      items.push({
        itemType: 'Asset',
        id: item
      })
    );
    const requestBody = {
      items
    };
    return httpService.post(urlConfigs.assetItemDetails, requestBody);
  },
  convertAssetDetailsToAsset(assetDetail: TAssetDetailsEntry): AccoutrementAsset {
    return {
      id: assetDetail.id,
      name: assetDetail.name,
      assetType: AvatarAccoutrementService.getAssetTypeById(assetDetail.assetType)
    } as AccoutrementAsset;
  },
  getBundleDetails(targetId: number): AxiosPromise<Array<TBundleDetailsEntry>> {
    const requestBody = {
      bundleIds: [targetId]
    };
    return httpService.get(urlConfigs.bundleItemDetails, requestBody);
  },
  get3DAssetThumbnail(targetId: number): AxiosPromise<unknown> {
    const requestBody = {
      assetId: targetId
    };
    return httpService.get(urlConfigs.asset3DThumbnail, requestBody);
  },
  get2DAssetThumbnail(targetId: number): AxiosPromise<Thumbnail> {
    const requestBody = {
      assetIds: [targetId],
      size: thumbnailConstants.size,
      format: thumbnailConstants.format
    };
    return httpService.get(urlConfigs.asset2DThumbnail, requestBody);
  },
  get3DAnimatedAssetThumbnail(targetId: number): AxiosPromise<unknown> {
    const requestBody = {
      assetId: targetId
    };

    return httpService.get(urlConfigs.animation3DThumbnail, requestBody);
  },
  get3DOutfitThumbnail(targetId: number): AxiosPromise<unknown> {
    const requestBody = {
      outfitId: targetId
    };

    return httpService.get(urlConfigs.outfits3DThumbnail, requestBody);
  },
  get2DBundleThumbnail(targetId: number): AxiosPromise<Thumbnail> {
    const requestBody = {
      bundleIds: [targetId],
      size: thumbnailConstants.size,
      format: thumbnailConstants.format,
      isCircular: false
    };
    return httpService.get(urlConfigs.asset2DThumbnail, requestBody);
  }
};

export default ItemDetailsThumbnailService;
